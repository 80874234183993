<template>
  <b-card no-body>
    <b-card-header>
      <div>
        <b-card-title class="mb-1">
          {{ $t('dashboard.finance.costs-and-incomes') }}
        </b-card-title>
        <b-card-sub-title>
          {{ $t('dashboard.finance.costs-and-incomes-by-orders') }}
        </b-card-sub-title>
      </div>

      <!-- datepicker -->
      <div class="d-flex align-items-center">
        <feather-icon
          icon="CalendarIcon"
          size="16"
          class="mr-1"
        />
        <flat-pickr
          id="month"
          v-model="localMonth"
          class="form-control"
          :config="configDateTimePicker"
          @on-close="monthChanged"
        />
      </div>
      <!-- datepicker -->
    </b-card-header>

    <b-card-body>
      <vue-apex-charts
        id="FinanceByOrderChart"
        type="bar"
        :height="chartHeight"
        :options="chartOptions"
        :series="series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardTitle, BCardSubTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { Polish as PolishLocale } from 'flatpickr/dist/l10n/pl'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import 'flatpickr/dist/plugins/monthSelect/style.css'
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect'
import moment from 'moment/moment'
import { $themeColors } from '@themeConfig'

export default {
  components: {
    BCard,
    VueApexCharts,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardSubTitle,

    flatPickr,
  },
  props: {
    statisticsData: {
      type: Object,
      default: () => {
      },
    },
    month: {
      type: String,
      default: moment().format('YYYY-MM'),
    },
  },
  data() {
    return {
      localMonth: this.month,
      configDateTimePicker: {
        locale: PolishLocale,
        disableMobile: true,
        plugins: [
          // eslint-disable-next-line new-cap
          new monthSelectPlugin({
            dateFormat: 'Y-m', // defaults to "F Y"
            altFormat: 'Y-m', // defaults to "F Y"
          }),
        ],
      },
    }
  },
  computed: {
    chartHeight() {
      const { length } = Object.keys(this.statisticsData.orders)
      if (length < 10) return 300
      return length * 20
    },
    chartOptions() {
      const categories = []
      Object.values(this.statisticsData.orders).forEach(order => {
        categories.push(order.number)
      })

      return {
        chart: {
          foreColor: '#777777',
          events: {
            click: event => {
              if (event.target.parentNode.className.baseVal === 'apexcharts-text apexcharts-yaxis-label '
                  && event.target.textContent.match(/^[P,S,T]\/20\d{2}\/\d{2}\/\d{4}$/i)) {
                this.$store.dispatch('order/identifyOrderByNumber', event.target.textContent)
                  .then(res => {
                    const { data } = res

                    if (data.id) {
                      if (this.$router.currentRoute.path !== `/order/view/${data.id}`) {
                        this.$router.push(`/order/view/${data.id}`)
                      }
                    } else {
                      window.toast.notify.warning(
                        this.$t('search.notify.order.warning.title'),
                        this.$t('search.notify.order.warning.description'),
                      )
                    }
                  })
              }
            },
          },
        },
        toolbar: {
          show: true,
        },
        dataLabels: {

          style: {
            colors: ['#F44336', '#E91E63', '#9C27B0'],
          },
          enabled: false,
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        legend: {
          show: true,
          fontSize: '14px',
          fontFamily: 'Montserrat',
          fontColor: 'white',
        },
        xaxis: {
          tickPlacement: 'on',
          categories,
        },
        colors: [$themeColors.danger, $themeColors.success],
      }
    },
    series() {
      const costs = []
      const incomes = []
      Object.values(this.statisticsData.orders).forEach(value => {
        costs.push(value.cost)
        incomes.push(value.income)
      })

      return [
        {
          name: this.$t('dashboard.finance.costs'),
          data: costs,
        },
        {
          name: this.$t('dashboard.finance.incomes'),
          data: incomes,
        },
      ]
    },
  },
  watch: {
    month: {
      handler(month) {
        this.localMonth = month
      },
    },
  },
  methods: {
    monthChanged() {
      this.$nextTick(() => this.$emit('changed:month', this.localMonth))
    },
  },
}
</script>

<style lang="scss">
#FinanceByOrderChart {
  .apexcharts-text {
    &.apexcharts-yaxis-label {
      cursor: pointer !important;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
