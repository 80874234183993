<template>
  <b-card no-body>
    <b-card-header>
      <div>
        <b-card-title class="mb-1">
          {{ $t('dashboard.finance.costs-and-incomes') }}
        </b-card-title>
        <b-card-sub-title>
          {{ $t('dashboard.finance.costs-and-incomes-by-days') }}
        </b-card-sub-title>
      </div>

      <!-- datepicker -->
      <div class="d-flex align-items-center">
        <feather-icon
          icon="CalendarIcon"
          size="16"
          class="mr-1"
        />
        <flat-pickr
          id="month"
          v-model="localMonth"
          class="form-control"
          :config="configDateTimePicker"
          @on-close="monthChanged"
        />
      </div>
      <!-- datepicker -->
    </b-card-header>

    <b-card-body>
      <vue-apex-charts
        type="area"
        height="400"
        :options="chartOptions"
        :series="series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardTitle, BCardSubTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { Polish as PolishLocale } from 'flatpickr/dist/l10n/pl'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import 'flatpickr/dist/plugins/monthSelect/style.css'
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect'
import moment from 'moment/moment'
import { $themeColors } from '@themeConfig'

export default {
  components: {
    BCard,
    VueApexCharts,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardSubTitle,

    flatPickr,
  },
  props: {
    statisticsData: {
      type: Object,
      default: () => {
      },
    },
    month: {
      type: String,
      default: moment().format('YYYY-MM'),
    },
  },
  data() {
    return {
      localMonth: this.month,
      configDateTimePicker: {
        locale: PolishLocale,
        disableMobile: true,
        plugins: [
          // eslint-disable-next-line new-cap
          new monthSelectPlugin({
            dateFormat: 'Y-m', // defaults to "F Y"
            altFormat: 'Y-m', // defaults to "F Y"
          }),
        ],
      },
    }
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          foreColor: '#777777',
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          // show: false,
          curve: 'straight',
        },
        legend: {
          show: true,
          fontSize: '14px',
          fontFamily: 'Montserrat',
        },
        xaxis: {
          categories: Object.keys(this.statisticsData.days),
        },
        colors: [$themeColors.danger, $themeColors.success],
      }
    },
    series() {
      const costs = []
      const incomes = []
      Object.values(this.statisticsData.days).forEach(value => {
        costs.push(value.cost)
        incomes.push(value.income)
      })

      return [
        {
          name: this.$t('dashboard.finance.costs'),
          data: costs,
        },
        {
          name: this.$t('dashboard.finance.incomes'),
          data: incomes,
        },
      ]
    },
  },
  watch: {
    month: {
      handler(month) {
        this.localMonth = month
      },
    },
  },
  methods: {
    monthChanged() {
      this.$nextTick(() => this.$emit('changed:month', this.localMonth))
    },
  },
}
</script>
