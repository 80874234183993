<template>
  <div>
    <b-row class="match-height">

      <b-col md="4">

        <b-card class="text-center card-justify">
          <rc-overlay :show="loading">
            <b-avatar
              class="mb-2"
              :variant="balance > 0 ? 'light-success' : 'light-danger'"
              size="72"
            >
              <feather-icon
                size="21"
                icon="DollarSignIcon"
              />
            </b-avatar>
            <div class="truncate">
              <h2 class="mb-1 font-weight-bolder">
                {{ balance }} {{ $t('shared.currency-symbol') }}
              </h2>
              <span>{{ $t('dashboard.finance.balance') }}</span>
            </div>
          </rc-overlay>
        </b-card>

      </b-col>

      <b-col md="4">

        <b-card class="text-center card-justify">
          <rc-overlay :show="loading">
            <b-avatar
              class="mb-2"
              variant="light-success"
              size="72"
            >
              <feather-icon
                size="21"
                icon="TrendingUpIcon"
              />
            </b-avatar>
            <div class="truncate">
              <h2 class="mb-1 font-weight-bolder">
                {{ statisticsData.totalIncomes.toFixed(2) }} {{ $t('shared.currency-symbol') }}
              </h2>
              <span>{{ $t('dashboard.finance.total-incomes') }}</span>
            </div>
          </rc-overlay>
        </b-card>

      </b-col>

      <b-col md="4">

        <b-card class="text-center card-justify">
          <rc-overlay :show="loading">
            <b-avatar
              class="mb-2"
              variant="light-warning"
              size="72"
            >
              <feather-icon
                size="21"
                icon="TrendingDownIcon"
              />
            </b-avatar>
            <div class="truncate">
              <h2 class="mb-1 font-weight-bolder">
                {{ statisticsData.totalCosts.toFixed(2) }} {{ $t('shared.currency-symbol') }}
              </h2>
              <span>{{ $t('dashboard.finance.total-costs') }}</span>
            </div>
          </rc-overlay>
        </b-card>

      </b-col>

    </b-row>

    <b-row>
      <b-col cols="12">
        <finance-days-chart
          :statistics-data="statisticsData"
          :month="month"
          @changed:month="changedMonth"
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <finance-orders-chart
          :statistics-data="statisticsData"
          :month="month"
          @changed:month="changedMonth"
        />
      </b-col>
    </b-row>
    <b-alert
      :show="false"
      variant="danger"
      class="p-1"
    >
      <p class="font-weight-bold font-medium-3">
        Statystyki (jeszcze) nie uwzględniają:
      </p>
      <p class="font-weight-bold font-medium-2">
        Kosztów
      </p>
      <ul>
        <li>Stałych, związanych z miesięcznym utrzymaniem pojazdów</li>
        <li>Pracowników nie biorących udziału w realizacji zleceń (brak grafików)</li>
        <li>Materiałów zużytych do realizacji zleceń</li>
        <li>Biura i innych kosztów utrzymania firmy</li>
      </ul>
      <p class="font-weight-bold font-medium-2">
        Przychodów
      </p>
      <ul>
        <li>Ze zleceń transportu zbiorowego, np. dializy</li>
        <li>Ze zleceń związanych z zabezpieczeniem</li>
        <li>Innych (jakich?)</li>
      </ul>
    </b-alert>
  </div>
</template>

<script>
import guard from '@/guard'
import {
  cloneNested, parseRequestError, empty, strToDecimal,
} from '@/helpers/helpers'
import moment from 'moment/moment'
import RcOverlay from '@/layouts/components/rc/RcOverlay.vue'
import {
  BAlert,
  BAvatar, BCard, BCol, BRow,
} from 'bootstrap-vue'
import FinanceOrdersChart from '@/views/dashboard/Finance/FinanceOrdersChart.vue'
import FinanceDaysChart from '@/views/dashboard/Finance/FinanceDaysChart.vue'

export default {
  components: {
    RcOverlay,

    FinanceOrdersChart,
    FinanceDaysChart,

    BRow,
    BCol,
    BCard,
    BAvatar,
    BAlert,
  },
  data() {
    const statisticsDataModel = {
      totalIncomes: 0,
      totalCosts: 0,
      days: {},
      orders: {},
    }
    return {
      loading: false,
      month: moment().format('YYYY-MM'),
      statisticsDataModel: cloneNested(statisticsDataModel),
      statisticsData: cloneNested(statisticsDataModel),
    }
  },
  computed: {
    me() {
      return this.$store.state.auth.activeUser
    },
    myTeam() {
      return this.$store.state.auth.userTeam
    },
    balance() {
      return (this.statisticsData.totalIncomes - this.statisticsData.totalCosts).toFixed(2)
    },
  },
  beforeMount() {
    this.init()
  },
  methods: {
    hasAtLeastOneRole: guard.hasAtLeastOneRole,
    init() {
      this.loading = true
      Promise.all([
        this.$store.dispatch('order/fetchFinancialResultsByOrder', this.month),
        this.$store.dispatch('team/fetchDowntimeCostsByDay', this.month),
      ])
        .then(res => {
          this.prepareDataFromResponses(res[0], res[1])
        })
        .catch(err => parseRequestError(err))
        .finally(() => {
          this.loading = false
        })
    },
    changedMonth(newMonth) {
      this.month = newMonth
      this.$nextTick(() => this.init())
    },
    clearStatisticsData() {
      this.statisticsData = cloneNested(this.statisticsDataModel)
    },
    prepareDataFromResponses(financialResultsByOrder, downtimeCostsByDay) {
      this.clearStatisticsData()
      // console.log(financialResultsByOrder, downtimeCostsByDay)

      financialResultsByOrder.forEach(result => {
        const date = moment(result.transportAt).format('YYYY-MM-DD')

        this.statisticsData.totalIncomes += strToDecimal(result.totalIncomes)
        this.statisticsData.totalCosts += strToDecimal(result.totalCosts)

        if (empty(this.statisticsData.days[date])) {
          this.statisticsData.days[date] = {
            cost: strToDecimal(result.totalCosts),
            income: strToDecimal(result.totalIncomes),
          }
        } else {
          this.statisticsData.days[date].cost += strToDecimal(result.totalCosts)
          this.statisticsData.days[date].income += strToDecimal(result.totalIncomes)
        }

        this.statisticsData.days[date].cost = strToDecimal(this.statisticsData.days[date].cost)
        this.statisticsData.days[date].income = strToDecimal(this.statisticsData.days[date].income)

        if (empty(this.statisticsData.orders[result.fullNumber])) {
          this.statisticsData.orders[result.fullNumber] = {
            number: result.fullNumber,
            cost: strToDecimal(result.totalCosts),
            income: strToDecimal(result.totalIncomes),
          }
        } else {
          this.statisticsData.orders[result.fullNumber].cost = strToDecimal(this.statisticsData.orders[result.fullNumber].cost + result.totalCosts)
          this.statisticsData.orders[result.fullNumber].income = strToDecimal(this.statisticsData.orders[result.fullNumber].income + result.totalIncomes)
        }
      })

      downtimeCostsByDay.forEach(result => {
        this.statisticsData.totalCosts += strToDecimal(result.driverDowntimeCost)
        this.statisticsData.totalCosts += strToDecimal(result.paramedicDowntimeCost)

        if (empty(this.statisticsData.days[result.date])) {
          this.statisticsData.days[result.date] = {
            cost: strToDecimal(result.driverDowntimeCost) + strToDecimal(result.paramedicDowntimeCost),
            income: 0,
          }
        } else {
          this.statisticsData.days[result.date].cost += strToDecimal(result.driverDowntimeCost)
          this.statisticsData.days[result.date].cost += strToDecimal(result.paramedicDowntimeCost)
        }
        this.statisticsData.days[result.date].cost = strToDecimal(this.statisticsData.days[result.date].cost)
      })
    },
  },
}
</script>
